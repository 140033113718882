<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'restaurierung-wiesbaden',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/restaurierung-wiesbaden',
      imageCount: 27,
      imageFileFormat: '.jpg',
      name: 'Restaurierung eines Altbau-Treppenhauses in Wiesbaden',
      description:
        'Restaurierung eines Altbau-Treppenhauses in Wiesbaden. Nach einer Sanierung der Hausinstallation waren im kompletten Treppenhaus einige Renovierungs- und Restaurierungsarbeiten fällig. Abgesehen von bestimmten Baumaterialien, die man berücksichtigen musste, wurden hier sehr feine Maler- und Lackierarbeiten ausgeführt. Einige spezielle Schreinerarbeiten, sowie Sonderanferigungen waren notwendig, um Altes zu bewahren und detailgetreu wieder aufzubauen. (Der Treppen- bzw. Bodenbelag wird noch erneuert!)',
    },
    //
  }),
};
</script>
